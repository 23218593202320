import React from 'react'
import Button from './button'

function SignIn({ signIn, updateFormState }) {
    return (
        <div className="flex flex-col space-y-4">
            <input 
                name='username'
                className="p-2 text-black"
                onChange={e => {e.persist(); updateFormState(e)}}
                placeholder='username'
            />
            <input 
                type='password'
                name='password'
                className="p-2 text-black"
                onChange={e => {e.persist(); updateFormState(e)}}
                placeholder='password'
            />
            <Button className="text-center" onClick={signIn}
                title={"Sign In"}
            />
        </div>
    )
}

export default SignIn