import React from 'react'
import Button from './button'

function ConfirmSignUp(props) {
    return (
        <div className="flex flex-col space-y-4">
            <input 
                name='confirmationCode'
                className="p-2 text-black"
                placeholder='Confirmation Code'
                onChange={e => {e.persist();props.updateFormState(e)}}
            />
            <Button 
                onClick={props.confirmSignUp}
                title="Confirm Sign Up"
            />
        </div>
    )
}

export default ConfirmSignUp
