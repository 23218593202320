import React, { useState, useEffect, useReducer } from 'react'
import { Link } from 'gatsby'
import Form from '../components/authFlow/form'
import Amplify, { API, Auth, Hub } from 'aws-amplify'
import config from '../aws-exports'
import { v4 as uuid } from 'uuid'
import { listRooms } from '../graphql/queries'
import { createRoom as CreateRoom,
} from '../graphql/mutations'
import { onCreateRoom } from '../graphql/subscriptions'
import Layout from '../components/layout'

Amplify.configure(config)

const slugify = require('slugify');

const Profile = () => {
    useEffect(() => {
        checkUser()
        Hub.listen('auth', (data) => {
            const { payload } = data
            if (payload.event === 'signOut') {
                setUser(null)
            }
        })
    }, [])

    const initialState = {
        rooms: [{
            id: '',
            title: '',
            loading: true,
            error: false,
        }],
        form: { name: '' }
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    function reducer(state, action) {
      switch(action.type) {
        case 'SET_ROOMS':
          return { ...state, rooms: action.rooms, loading: false }
        case 'ADD_ROOM':
          return { ...state, rooms: [action.newroom, ...state.rooms] }
        case 'SET_INPUT':
          return { ...state, form: { ...state.form, [action.name]: action.value }}
        case 'RESET_FORM':
          return { ...state, form: initialState.form }
        case 'ERROR':
          return { ...state, loading: false, error: true }
        default:
          return state
      }
    }
  
    const [user, setUser] = useState(null)

    function onChange(e) {
        dispatch({ type: 'SET_INPUT', name: e.target.name, value: e.target.value })
    }

    async function checkUser() {
        try {
            const data = await Auth.currentUserPoolUser()
            const userInfo = { userName: data.username, ...data.attributes,}
            setUser(userInfo)
        } catch (err) {
            console.log('error: ', err)
        }
    }

    function signOut() {
        Auth.signOut()
            .catch(err => console.log('error signing out: ', err))
    }

    async function fetchRooms() {
        try {
          const roomData = await API.graphql({
            query: listRooms,
            authMode: "AMAZON_COGNITO_USER_POOLS"
          })
          dispatch({ type: 'SET_ROOMS', rooms: roomData.data.listRooms.items })
        } catch(err) {
          console.log('error: ', err)
          dispatch({ type: 'ERROR' })
        }
    }

    useEffect(() => {
        fetchRooms()
        const subscription = API.graphql({
          query: onCreateRoom,
          authMode: "AMAZON_COGNITO_USER_POOLS"
        })
        .subscribe({
          next: roomData => {
            const room = roomData.value.data.onCreateRoom
            dispatch({ type: 'ADD_ROOM', room})
          }
        })
        return () => subscription.unsubscribe()
      }, [])

    async function createRoom(title) {
        const newroom = {
                          title: title, 
                          id: uuid(),
                        }
        dispatch({ type: 'ADD_ROOM', newroom })
        dispatch({ type: 'RESET_FORM' })
        try {
          await API.graphql({
            query: CreateRoom,
            variables: { input: newroom },
            authMode: "AMAZON_COGNITO_USER_POOLS"
          })
          console.log('success!')
        } catch (err) {
          console.log('error: ', err)
        }
      
    }

    const buttonClass = "inline-flex items-center px-3 py-2 border border-gray-300 leading-4 font-medium rounded-md text-black bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
    
    if (user) {
        return (
          <Layout>
            <div className="space-y-4">
                <h1>Profile</h1>
                <h2>Username: {user.username}</h2>
                <h3>Email: {user.email}</h3>
                <button onClick={signOut}>Sign Out</button>
                <div className="m-2">
                <div className="mt-1 relative rounded-md shadow-sm">
                    <label htmlFor="room name" className="block text-sm font-medium leading-5">Add a name for the room
                    <input 
                        id="room name"
                        onChange={onChange}
                        value={state.form.name}
                        name="name"
                        className="text-black w-5/6 sm:w-1/4 p-2 my-2" 
                        placeholder="The Mouth of Madness" />
                        </label>
                    </div>
                    <button
                        className={buttonClass}
                        onClick={() => {
                            createRoom(state.form.name)
                        }}
                    >Create Room</button>
                </div>
                <div className="m-2">Room URLs:</div>
                {state.rooms.map((i, index) => {
                    return(
                    i !== null ?
                    <div key={index} className="m-2 underline">
                      <Link to={`/room/${slugify(i.title).toLowerCase()}`}>
                        {i.title}
                      </Link>
                    </div>
                    :
                    <div>No rooms yet. Setting up for subs</div>
                )})}
            </div>
          </Layout>
        );
    }
    return <Form setUser={setUser} />
}

export default Profile
