import React from 'react'

export default function Button({ onClick, title }) {
    return (
        <span className="inline-flex rounded-md shadow-sm">
            <button
                className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
                onClick={onClick}
            >
                <span className="p-2 font-bold">{title}</span>
            </button>
        </span>
    )
}