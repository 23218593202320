/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateRoom = /* GraphQL */ `
  subscription OnCreateRoom {
    onCreateRoom {
      id
      name
      dicerollarray {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRoom = /* GraphQL */ `
  subscription OnUpdateRoom {
    onUpdateRoom {
      id
      name
      dicerollarray {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRoom = /* GraphQL */ `
  subscription OnDeleteRoom {
    onDeleteRoom {
      id
      name
      dicerollarray {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDiceRoll = /* GraphQL */ `
  subscription OnCreateDiceRoll {
    onCreateDiceRoll {
      id
      roomId
      playerId
      name
      savedRollName
      dice
      bonus
      results
      ttl
      room {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDiceRoll = /* GraphQL */ `
  subscription OnUpdateDiceRoll {
    onUpdateDiceRoll {
      id
      roomId
      playerId
      name
      savedRollName
      dice
      bonus
      results
      ttl
      room {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDiceRoll = /* GraphQL */ `
  subscription OnDeleteDiceRoll {
    onDeleteDiceRoll {
      id
      roomId
      playerId
      name
      savedRollName
      dice
      bonus
      results
      ttl
      room {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
