/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getRoom = /* GraphQL */ `
  query GetRoom($id: ID!) {
    getRoom(id: $id) {
      id
      name
      dicerollarray {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listRooms = /* GraphQL */ `
  query ListRooms(
    $filter: ModelRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDiceRoll = /* GraphQL */ `
  query GetDiceRoll($id: ID!) {
    getDiceRoll(id: $id) {
      id
      roomId
      playerId
      name
      savedRollName
      dice
      bonus
      results
      ttl
      room {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listDiceRolls = /* GraphQL */ `
  query ListDiceRolls(
    $filter: ModelDiceRollFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDiceRolls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        roomId
        playerId
        name
        savedRollName
        dice
        bonus
        results
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
