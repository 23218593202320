import React from 'react'
import Button from './button'

function ForgotPassword(props) {
    return (
        <div className="flex flex-col space-y-4">
            <input 
                name='username'
                className="p-2 text-black"
                placeholder='Username'
                onChange={e => {e.persist();props.updateFormState(e)}}
            />
            <Button 
                onClick={props.forgotPassword}
                title='Reset Password'
            />
        </div>
    )
}

export default ForgotPassword