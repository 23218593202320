import React, { useState } from 'react'
import Amplify, { Auth } from 'aws-amplify'
import SignIn from './signIn'
import SignUp from './signUp'
import ConfirmSignUp from './confirmSignUp'
import ForgotPassword from './forgotPassword'
import ForgotPasswordSubmit from './forgotPasswordSubmit'
import config from '../../aws-exports'
import '../../utils/styles.css'
import Layout from '../layout'

Amplify.configure(config)


async function signIn({ username, password }, setUser) {
    try {
        const user = await Auth.signIn(username, password)
        const userInfo = { username: user.username, ...user.attributes}
        setUser(userInfo)
    } catch (err) {
        console.log('error signing up..', err)
    }
}

async function signUp({ username, password, email }, updateFormType) {
    try {
        await Auth.signUp({
            username, password, attributes: { email }
        })
        console.log('sign up success')
        updateFormType('confirmSignUp')
    } catch (err) {
        console.log('error signing up...', err)
    }
}

async function confirmSignUp({ username, confirmationCode }, updateFormType) {
    try {
        await Auth.confirmSignUp(username, confirmationCode)
        updateFormType('signIn')
    } catch (err) {
        console.log('error signing up', err)
    }
}

async function forgotPassword({ username }, updateFormType) {
    try {
        await Auth.forgotPassword(username)
        updateFormType('forgotPasswordSubmit')
    } catch (err) {
        console.log('error submitting username to reset password...', err)
    }
}

async function forgotPasswordSubmit({ username, confirmationCode, password }, updateFormType) {
    try {
        await Auth.forgotPasswordSubmit(username, confirmationCode, password)
        updateFormType('signIn')
    } catch (err) {
        console.log('error updating password...', err)
    }
}


const initialFormState = {
    username: '',
    password: '',
    email: '',
    confirmationCode: ''
}

function Form(props) {
    const [formType, updateFormType] = useState('signIn')
    const [formState, updateFormState] = useState(initialFormState)

    function updateForm(event) {
        const newFormState = {
            ...formState,
            [event.target.name]: event.target.value
        }
        updateFormState(newFormState)
    }

    function renderForm() {
        switch(formType) {
            case 'signUp':
                return (
                    <SignUp 
                        signUp={() => signUp(formState, updateFormType)}
                        updateFormState={e => updateForm(e)}
                    />
                )
            case 'confirmSignUp':
                return (
                    <ConfirmSignUp 
                        confirmSignUp={() => confirmSignUp(formState, updateFormType)}
                        updateFormState={e => updateForm(e)}
                    />
                )
            case 'signIn':
                return (
                    <SignIn 
                        signIn={() => signIn(formState, props.setUser)}
                        updateFormState={ e => updateForm(e)}
                    />
                )
            case 'forgotPassword':
                return (
                    <ForgotPassword
                        forgotPassword={() => forgotPassword(formState, updateFormType)}
                        updateFormState={e => updateForm(e)}
                    />
                )
            case 'forgotPasswordSubmit':
                return (
                    <ForgotPasswordSubmit 
                        forgotPassword={() => forgotPasswordSubmit(formState, updateFormType)}
                        updateFormState={e => updateForm(e)}
                    />
                )
            default:
                return null
        }
    }

    return (
        <Layout>
        <div className="flex flex-row justify-center mt-20">
        <div className="space-y-4">
            {renderForm()}
            {
                formType === 'signUp' && (
                    <p>
                        <span className="mr-4">Already have an account?</span>
                        <span role="button" tabIndex={0} onClick={() => updateFormType('signIn')} onKeyDown={() => updateFormType('signIn')}>
                            Sign In
                        </span>
                    </p>
                )
            }
            {
                formType === 'signIn' && (
                    <>
                        <p>
                        <span className="mr-4">Need an account?</span>
                            <span 
                                className="space-x-2"
                                role="button"
                                tabIndex={0}
                                onClick={() => updateFormType('signUp')}
                                onKeyDown={() => updateFormType('signUp')}
                                >
                                Sign Up
                            </span>
                        </p>
                        <p>
                            <span className="mr-4">Forget your password?</span>
                            <span
                                role="button"
                                tabIndex={0}
                                onClick={() => updateFormType('forgotPassword')}
                                onKeyDown={() => updateFormType('forgotPassword')}
                                >
                                Reset Password
                            </span>
                        </p>
                    </>
                )
            }
        </div>
        </div>
        </Layout>
    )
}

export default Form;
