import React from 'react'
import Button from './button'

function ForgotPasswordSubmit(props) {
    return (
        <div>
            <input 
                name='confirmationcode'
                placeholder='Confirmation code'
                className="p-2 text-black"
                onChange={e => {e.persist();props.updateFormState(e)}}
            />
            <Button onClick={props.forgotPasswordSubmit} 
                title="Save new password"
            />
        </div>
    )
}

export default ForgotPasswordSubmit
