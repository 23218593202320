import React from 'react'
import Button from './button'

function SignUp({ updateFormState, signUp}) {
    return (
        <div className="flex flex-col space-y-4">
            <input 
                name='username'
                className="p-2 text-black"
                onChange={e => {e.persist();updateFormState(e)}}
                placeholder='username'
            />
            <input 
                type='password'
                name='password'
                className="p-2 text-black"
                onChange={e => {e.persist(); updateFormState(e)}}
                placeholder='password'
            />
            <input 
                name='email'
                className="p-2 text-black"
                onChange={e => {e.persist();updateFormState(e)}}
                placeholder='email'
            />
            <Button onClick={signUp}
                title='Sign Up'
            />
        </div>
    )
}

export default SignUp;
